.custom-mt .layout_top,
.custom-mt .layout_nav,
.custom-mt .tab-bar,
.custom-mt .layout_jubao .layout_top,
.custom-mt .layout_fabu .layout_top,
.custom-mt .layout_zhuanfa .layout_top,
.custom-mt .layout_select_huati .layout_top,
.custom-mt .layout_personal_center .layout_top.layout_top_scroll,
.custom-mt .layout_personal_edit_username .layout_top,
.custom-mt .layout_search .layout_top,
.custom-mt .layout_information .layout_top,
.custom-mt .layout_talk .layout_top.layout_top_scroll,
.custom-mt .layout_history .layout_top {
  background: #282f41;
}
.custom-mt .gametitle,
.custom-mt .layout_top_header .user-box-index-name,
.custom-mt .layout_return,
.custom-mt .personal_box_autograph,
.custom-mt .personal_box_autograph {
  color: #d7dadd;
}
.custom-mt .nav-list__item,
.custom-mt .personal-operation {
  color: #a4a4ad;
}
.custom-mt .nav-list__item.is-active,
.custom-mt .game_tequan_userInfor .personal-index-name span,
.custom-mt .layout_member_box_title,
.custom-mt .personal-index-name span,
.custom-mt .personal-index-id span,
.custom-mt .modify-head {
  color: #fff;
}
.custom-mt .nav-list__item em,
.custom-mt .selected_huati_wrap .search-synthetic-ph-btn .search-synthetic-ph-placeholder,
.custom-mt .antd-pro-components-editor-toolbar-index-sendBtn,
.custom-mt .talk_banner_box .operation-index-follow,
.custom-mt .edit-user-save,
.custom-mt .edit-user-sure,
.custom-mt .layout_member_main .layout_member_box_progressbar,
.custom-mt .layout_member_box_progressbar div,
.custom-mt .game_tequan_list_btn,
.custom-mt .personal-box-concern,
.custom-mt .personal-box-information,
.custom-mt .personal_informaion_title ul li a .information-num,
.custom-mt .unread,
.custom-mt .tab-bar-tab.tab04 .tab-bar-tab-icon .infor_more,
.custom-mt .tab-bar-tab.tab05 .tab-bar-tab-icon .infor_more,
.custom-mt .loginbox_btn,
.custom-mt .loginbox_btn_btn,
.custom-mt .layout_setbox_card,
.custom-mt .lcalendar_finish,
.custom-mt .lbcopy,
.custom-mt .lbcenter_list_btn,
.custom-mt .loginbox_dhbtn,
.custom-mt .mall_dh,
.custom-mt .layout_personal_center_link_list .information-num {
  background: #e79d28;
}
.custom-mt .operation-index-follow {
  border-color: #e79d28;
  color: #e79d28;
}
.custom-mt .operation-index-followed {
  border-color: #ccc;
  color: #ccc;
}
.custom-mt .search_result_content ul li {
  background: rgba(231, 157, 40, 0.5);
}
.custom-mt .search_result_content ul li p,
.custom-mt .stage_jifen p span {
  color: #7a541a;
}
.custom-mt .classify__item.is-active {
  background: rgba(231, 157, 40, 0.5);
  color: #7a541a;
}
.custom-mt .notice__item.is-active {
  border-color: #e79d28;
  color: #e79d28;
}
.custom-mt .layout_return span,
.custom-mt .layout_talk .layout_top.layout_top_scroll .layout_return span {
  border-color: #8a8a8a;
}
.custom-mt .list-index-name,
.custom-mt .post-card-repost .post-card-repost-txt .post-card-repost-name,
.custom-mt .huati_tab_item.is-active,
.custom-mt .selected_huati_list_box li a:after,
.custom-mt .jifen__item.is-active,
.custom-mt .layout_jifen_list_txt p span,
.custom-mt .layout_jifen_list_num,
.custom-mt .layout_member_box_num,
.custom-mt .layout_personal_center_name_username,
.custom-mt .personal_informaion_title ul li.icon_active a,
.custom-mt .personal_information_list_operate,
.custom-mt .select_delete,
.custom-mt .loginbox_tips span,
.custom-mt .loginbox_tips a,
.custom-mt .lcalendar_cancel,
.custom-mt .qiandao-btn,
.custom-mt .user-box-index-checking,
.custom-mt .selectlink {
  color: #e79d28;
}
.custom-mt .talk_edit_icon {
  background-color: #e79d28;
}
.custom-mt .layout_personal_center .layout_sub {
  background-image: url(../assets/images/mt/pic05.jpg);
}
.custom-mt .personal_jifen_box_num {
  background: rgba(40, 47, 65, 0.5);
}
.custom-mt .layout_personal_center .layout_close {
  background-image: url(../assets/images/close.png);
}
.custom-mt .layout_member_box_progressbar {
  border-color: #e79d28;
}
.custom-mt .personal-operation .operation_icon {
  background-image: url(../assets/images/mt/icon39.png);
}
.custom-mt .personal-operation .edit_icon {
  background-image: url(../assets/images/mt/edit.png);
}
.custom-mt .layout_personal_edit .personal_information {
  background-image: url(../assets/images/mt/pic06.jpg);
}
.custom-mt .select_header ul li img {
  border-color: rgba(231, 157, 40, 0.5);
}
.custom-mt .select_header ul li.select_active img {
  border-color: #e79d28;
}
.custom-mt .select_operate .tab-bar {
  background: transparent;
}
.custom-mt .send-action-item-dynamic .send-action-item-icon {
  background: url(../assets/images/mt/icon15.png) no-repeat 50% 50% / auto 20px;
}
.custom-mt .send-action-item-posts .send-action-item-icon {
  background: url(../assets/images/mt/icon16.png) no-repeat 50% 50% / auto 20px;
}
.custom-mt .send-action-item .send-action-item-icon {
  background-color: #eeba68;
}
.custom-mt .post-card-box-index-action-zan_click {
  background-image: url(../assets/images/mt/icon09.png);
}
.custom-mt .post-card-box-index-action-not_click {
  background-image: url(../assets/images/mt/icon19.png);
}
.custom-mt .layout_member_box_title img {
  -webkit-filter: brightness(0%);
  filter: brightness(0%);
}
.custom-mt .qiandao-btn-followed {
  background: rgba(231, 157, 40, 0.5);
  color: #7a541a;
}
.custom-mt .loginbox_lqbtn {
  background-image: url(../assets/images/mt/lqbtn.png);
}
.custom-mt .personal_informaion_title ul li a.icon_infor_reply {
  background: url(../assets/images/mt/icon27.png) no-repeat 50% 0;
  background-size: 50px;
}
.custom-mt .personal_informaion_title ul li a.icon_infor_zan {
  background: url(../assets/images/mt/icon28.png) no-repeat 50% 0;
  background-size: 50px;
}
.custom-mt .personal_informaion_title ul li a.icon_infor_fans {
  background: url(../assets/images/mt/icon31.png) no-repeat 50% 0;
  background-size: 50px;
}
.custom-mt .personal_informaion_title ul li a.icon_infor_system {
  background: url(../assets/images/mt/icon29.png) no-repeat 50% 0;
  background-size: 50px;
}
.custom-mt .personal_informaion_title ul li a.icon_infor_follow {
  background: url(../assets/images/mt/icon30.png) no-repeat 50% 0;
  background-size: 50px;
}
.custom-mt .personal_informaion_title ul li.icon_active a.icon_infor_reply {
  background-image: url(../assets/images/mt/icon27_2.png);
}
.custom-mt .personal_informaion_title ul li.icon_active a.icon_infor_zan {
  background-image: url(../assets/images/mt/icon28_2.png);
}
.custom-mt .personal_informaion_title ul li.icon_active a.icon_infor_fans {
  background-image: url(../assets/images/mt/icon31_2.png);
}
.custom-mt .personal_informaion_title ul li.icon_active a.icon_infor_system {
  background-image: url(../assets/images/mt/icon29_2.png);
}
.custom-mt .personal_informaion_title ul li.icon_active a.icon_infor_follow {
  background-image: url(../assets/images/mt/icon30_2.png);
}
.custom-mt .loginbox_btn_qx {
  background: #a6a6a6;
}
.custom-mt .mall_dh_disabled {
  background: #f2f2f2;
  color: #666666;
}
