.custom-dy html,
.custom-dy body {
  color: #656564;
}
.custom-dy .layout_nav,
.custom-dy .layout_top_main,
.custom-dy .layout_personal_center .layout_top.layout_top_scroll {
  background: url(../assets/images/dy/top.jpg) no-repeat 0 0 / cover;
}
.custom-dy .nav-list__item {
  color: #000;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
}
.custom-dy .nav-list__item.is-active {
  background: url(../assets/images/dy/navbg.png) no-repeat 50% 50%;
  background-size: 100% 25px;
  color: #fff;
}
.custom-dy .nav-list__item em {
  display: none;
}
.custom-dy .tab-bar,
.custom-dy .border-one {
  border: none;
}
.custom-dy .top_pic {
  padding: 0;
}
.custom-dy #pictab {
  width: 100%;
}
.custom-dy #pictab img {
  border-radius: 0;
}
.custom-dy .layout_containter {
  background: url(../assets/images/dy/bg.jpg) no-repeat 0 0 / cover;
}
.custom-dy .news_list {
  background: url(../assets/images/dy/phbg.png) no-repeat;
  background-size: 100% 100%;
  margin: 10px 10px 0;
}
.custom-dy .news_list_content ul li a {
  color: #656564;
  border-bottom: 1px solid #cdcbc1;
}
.custom-dy .news_list_content ul li a p {
  border: none;
}
.custom-dy .latest_posts_tit {
  background: transparent;
  color: #656564;
  font-size: 15px;
  font-weight: 700;
}
.custom-dy .latest_posts_tit:before {
  width: 0;
}
.custom-dy .posts_sort {
  color: #656564;
}
.custom-dy .avatar-index-avatar,
.custom-dy .personal-avatar-index-avatar {
  border-radius: 0;
  border: 1px solid;
  border-image: linear-gradient(to bottom right, #e0ca95, #ae935f, #cbaa6f) 1;
  background: #cbaa6f;
}
.custom-dy .operation-index-follow {
  border: none;
  color: #656564;
  border-radius: 0;
  background: url(../assets/images/dy/gzbg.png) no-repeat;
  background-size: 100% 100%;
  width: auto;
  min-width: 52px;
  white-space: nowrap;
}
.custom-dy .operation-index-followed {
  background-image: url(../assets/images/dy/gzbg02.png);
}
.custom-dy .post-card-box-index-title,
.custom-dy .search_result_content ul li p {
  color: #656564;
}
.custom-dy .post-card-box-index-textContent,
.custom-dy .user-box-index-status {
  color: rgba(101, 101, 100, 0.6);
}
.custom-dy .search_result_content ul li {
  background: #eeddc2;
  border-radius: 20px;
}
.custom-dy .latest_posts_box {
  background: url(../assets/images/dy/postbg_top.png) no-repeat 0 0, url(../assets/images/dy/postbg_foot.png) no-repeat 0 100%;
  background-size: 100%;
  margin: 0 10px 10px;
  overflow: hidden;
  padding: 3.4% 0;
}
.custom-dy .latest_posts_box > div {
  padding: 0 4%;
  background: url(../assets/images/dy/postbg.png) repeat-y;
  background-size: 100%;
}
.custom-dy .latest_posts_box > div.user-box-index-userInfo {
  margin: 0;
  padding-bottom: 15px;
}
.custom-dy .post-card-box-index-action-collect {
  background: url(../assets/images/dy/icon06.png) no-repeat 0 50%;
  background-size: 18px;
}
.custom-dy .post-card-box-index-action-infor {
  background: url(../assets/images/dy/icon07.png) no-repeat 0 50%;
  background-size: 18px;
}
.custom-dy .post-card-box-index-action-zan {
  background: url(../assets/images/dy/icon08.png) no-repeat 0 50%;
  background-size: 18px;
}
.custom-dy .post-card-box-index-action-zan_click {
  background-image: url(../assets/images/dy/icon09.png);
}
.custom-dy .post-card-box-index-action-not {
  background-image: url(../assets/images/dy/icon18.png);
}
.custom-dy .post-card-box-index-action-not_click {
  background-image: url(../assets/images/dy/icon19.png);
}
.custom-dy .tab-bar {
  background: url(../assets/images/dy/foot.jpg) no-repeat 0 0 / cover;
  padding-bottom: 0;
}
.custom-dy .tab-bar-tab .tab-bar-tab-icon img {
  width: 42px;
}
.custom-dy .layout_foot_box {
  background: url(../assets/images/dy/top.jpg) no-repeat 0 0 / cover;
}
.custom-dy .antd-pro-components-comment-bar-index-commentBar .antd-pro-components-comment-bar-index-bar {
  height: 70px;
  background: none;
}
.custom-dy .antd-pro-components-like-index-icon {
  background: url(../assets/images/dy/icon08.png) no-repeat 50% 0;
  background-size: 18px;
}
.custom-dy .antd-pro-components-like-index-isActive .antd-pro-components-like-index-icon {
  background-image: url(../assets/images/dy/icon09.png);
}
.custom-dy .antd-pro-components-repost-index-icon {
  background: url(../assets/images/dy/icon06.png) no-repeat 50% 0;
  background-size: 18px;
}
.custom-dy .antd-pro-components-shoucang-index-icon {
  background: url(../assets/images/dy/icon20.png) no-repeat 50% 0;
  background-size: 18px;
}
.custom-dy .antd-pro-components-shoucang-index-isActive .antd-pro-components-shoucang-index-icon {
  background-image: url(../assets/images/dy/icon21.png);
}
.custom-dy .antd-pro-components-shoucang-index-isActive .antd-pro-components-badge-index-number,
.custom-dy .antd-pro-components-like-index-isActive .antd-pro-components-badge-index-number,
.custom-dy .post-card-box-index-action-zan_click {
  color: #c6ae85;
}
.custom-dy .layout_return {
  left: 10px;
  width: 26px;
  height: 100%;
  background: url(../assets/images/dy/return.png) no-repeat 0 50%;
  background-size: 90%;
  font-size: 0;
}
.custom-dy .layout_return span {
  width: 100%;
  height: 100%;
  border: none;
  -webkit-transform: none;
  transform: none;
  margin: 0;
}
.custom-dy .layout_detail .latest_posts_content {
  padding-top: 3.6%;
  background: url(../assets/images/dy/postbg_top.png) no-repeat;
  background-size: 100%;
  margin: 10px 10px 0;
}
.custom-dy .layout_detail .latest_posts_content .latest_posts_box {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
}
.custom-dy .layout_detail .components-commentList {
  border: none;
  margin: 0 10px 10px;
  padding: 0 0 3.6%;
  background: url(../assets/images/dy/postbg_foot.png) no-repeat 0 100%;
  background-size: 100%;
}
.custom-dy .layout_detail .components-commentList > div:not(.posts_sort) {
  padding: 0 4%;
  background: url(../assets/images/dy/postbg.png) repeat-y;
  background-size: 100%;
}
.custom-dy .layout_detail .components-commentList > div.layout_subnav {
  padding-top: 10px;
  border-top: 2px solid;
  border-color: #f4ecdc;
}
.custom-dy .post-card-box-index-action-look {
  background-image: url(../assets/images/dy/icon17.png);
  color: #848483;
}
.custom-dy .post-detail-forCard {
  color: rgba(101, 101, 100, 0.6);
}
.custom-dy .list-index-commentItem-sublist {
  background: #f9f6ef;
}
.custom-dy .list-index-name {
  color: #5d98fa;
}
.custom-dy .fabu-form {
  background: url(../assets/images/dy/top.jpg) no-repeat 0 0 / cover;
  border: none;
}
.custom-dy .layout_fabu .fabu_wrap,
.custom-dy .layout_fabu_action .fabu_content,
.custom-dy .layout_zhuanfa .fabu_content {
  background: url(../assets/images/dy/postbg_top.png) no-repeat 0 10px, url(../assets/images/dy/postbg_foot.png) no-repeat 0 100%;
  background-size: 100%;
  padding: 10px 0 3.4%;
}
.custom-dy .fabu_title {
  background: url(../assets/images/dy/postbg.png) repeat-y;
  background-size: 100%;
  margin: 3% 0 0;
}
.custom-dy .fabu_title div .fabu_int {
  border-color: #d2ccbb;
}
.custom-dy .layout_fabu_action .fabu_content,
.custom-dy .layout_zhuanfa .fabu_content {
  padding-top: 6%;
}
.custom-dy .layout_fabu .fabu_content,
.custom-dy .layout_fabu_action .textContent,
.custom-dy .layout_zhuanfa .textContent {
  background: url(../assets/images/dy/postbg.png) repeat-y;
  background-size: 100%;
}
.custom-dy .layout_fabu_action .textContent,
.custom-dy .layout_zhuanfa .textContent {
  padding: 0 10px;
}
.custom-dy .antd-pro-components-editor-toolbar-index-toolBox span {
  background-image: url(../assets/images/dy/icon23.png);
}
.custom-dy .antd-pro-components-editor-toolbar-index-fileBox span {
  background-image: url(../assets/images/dy/icon24.png);
  background-size: 25px;
}
.custom-dy .antd-pro-components-editor-toolbar-index-talkBox span {
  background-image: url(../assets/images/dy/icon25.png);
}
.custom-dy .antd-pro-components-editor-toolbar-index-toolbar .antd-pro-components-editor-toolbar-index-send {
  width: auto;
}
.custom-dy .antd-pro-components-editor-toolbar-index-send .antd-pro-components-editor-toolbar-index-sendBtn {
  background: url(../assets/images/dy/gzbg.png) no-repeat;
  background-size: 100% 100%;
  color: #656564;
  border-radius: 0;
  font-size: 16px;
}
.custom-dy .antd-pro-components-editor-toolbar-index-send.disabled .antd-pro-components-editor-toolbar-index-sendBtn {
  background: url(../assets/images/dy/gzbg02.png) no-repeat;
  background-size: 100% 100%;
}
.custom-dy .jubao_wrap,
.custom-dy .latest_posts_content {
  background: none;
}
.custom-dy .layout_personal_center .layout_sub,
.custom-dy .layout_personal_center .layout_main_box {
  background: none;
}
.custom-dy .layout_personal_center .layout_close {
  background-image: url(../assets/images/close.png);
}
.custom-dy .personal-avatar-index-img {
  border-radius: 0;
}
.custom-dy .personal-index-name span,
.custom-dy .personal-index-id span,
.custom-dy .personal_box_autograph,
.custom-dy .personal-info-box {
  color: #848483;
}
.custom-dy .personal-info-name {
  opacity: 0.6;
}
.custom-dy .personal-operation .edit_icon {
  background-image: url(../assets/images/dy/edit.png);
}
.custom-dy .qiandao-btn {
  background: url(../assets/images/dy/gzbg.png) no-repeat;
  background-size: 100%;
  color: #656564;
}
.custom-dy .qiandao-btn-followed {
  background-image: url(../assets/images/dy/gzbg02.png);
}
.custom-dy .personal-box-concern,
.custom-dy .personal-box-information {
  background: #c6ae85;
}
.custom-dy .layout_personal_center_index .personal_information {
  width: 92%;
  margin: 0 auto;
  background: url(../assets/images/dy/personal_top.png) no-repeat 0 64px;
  background-size: 100%;
  padding-bottom: 0;
}
.custom-dy .layout_personal_center_index .personal_information .personal_information_main {
  width: 100%;
  padding: 0 10px;
  margin-top: 2%;
  background: url(../assets/images/dy/personalbg.png) repeat-y;
  background-size: 100%;
  padding-bottom: 25px;
}
.custom-dy .layout_personal_center_index .personal_information + .layout_content .layout_personal_center_box {
  margin-top: 0;
  padding-bottom: 3.4%;
  background: url(../assets/images/dy/personal_foot.png) no-repeat 0 100%;
  background-size: 100%;
}
.custom-dy .layout_personal_center_name {
  margin-bottom: 0;
  border-radius: 0;
  background: url(../assets/images/dy/personalbg.png) repeat-y;
  background-size: 100%;
}
.custom-dy .layout_personal_center_link {
  border-radius: 0;
  background: url(../assets/images/dy/linkbg.png) no-repeat 0 100%;
  background-size: 100% 100%;
  padding: 25px 10px;
}
.custom-dy .layout_personal_center_link_list p:nth-child(2) {
  background: url(../assets/images/dy/linkbg02.png) no-repeat 50% 0;
  background-size: 65px 100%;
  margin-top: -10px;
  position: relative;
}
.custom-dy .layout_personal_center_link_list p img {
  max-width: 70px;
  margin: 0;
}
.custom-dy .layout_personal_center_link_list .information-num {
  top: 10px;
  left: 55%;
}
.custom-dy .layout_personal_index_tab .subnav-list > ul {
  text-align: center;
}
.custom-dy .jifen__item {
  color: rgba(132, 132, 131, 0.6);
}
.custom-dy .jifen__item.is-active {
  color: #848483;
  font-weight: 700;
}
.custom-dy .layout_personal_center .latest_posts_box {
  padding: 3.4% 0;
}
.custom-dy .layout_personal_edit .personal_information {
  background: none;
}
.custom-dy .layout_personal_edit .antd-pro-components-editor-toolbar-index-send .antd-pro-components-editor-toolbar-index-sendBtn {
  background: url(../assets/images/dy/btnbg.png) no-repeat;
  background-size: 100% 100%;
}
.custom-dy .personal-box-edit-header {
  border: 1px solid;
  border-image: linear-gradient(to bottom right, #e0ca95, #ae935f, #cbaa6f) 1;
  background: #cbaa6f;
}
.custom-dy .personal-box-edit-header img {
  border-radius: 0;
}
.custom-dy .select_header ul li img {
  padding: 0;
  border-radius: 0;
  border: 1px solid;
  border-image: linear-gradient(to bottom right, #e0ca95, #ae935f, #cbaa6f) 1;
  background: #cbaa6f;
}
.custom-dy .edit-username,
.custom-dy .edit-signature {
  padding: 74px 0 3.4%;
  width: 92%;
  margin: 0 auto;
  background: url(../assets/images/dy/postbg_top.png) no-repeat 0 64px, url(../assets/images/dy/postbg_foot.png) no-repeat 0 100%;
  background-size: 100%;
}
.custom-dy .edit-username-label {
  margin-top: 2%;
  background: url(../assets/images/dy/postbg.png) repeat-y;
  background-size: 100%;
}
.custom-dy .edit-user-formItems,
.custom-dy .lbcenter_list,
.custom-dy .layout_setbox_content,
.custom-dy .mall_list {
  padding: 3.4% 0;
  width: 92%;
  margin: 0 auto;
  background: url(../assets/images/dy/postbg_top.png) no-repeat 0 0, url(../assets/images/dy/postbg_foot.png) no-repeat 0 100%;
  background-size: 100%;
}
.custom-dy .edit-user-formItems .edit-user-formItem {
  width: 100%;
  background: url(../assets/images/dy/postbg.png) repeat-y;
  background-size: 100%;
  padding: 20px 10px;
}
.custom-dy .edit-user-formItems .edit-user-formItem:last-child {
  border: none;
}
.custom-dy .layout_personal_edit .antd-pro-components-editor-toolbar-index-send {
  max-width: 390px;
  height: auto;
  border-radius: 0;
}
.custom-dy .layout_personal_edit .antd-pro-components-editor-toolbar-index-send .antd-pro-components-editor-toolbar-index-sendBtn {
  line-height: normal;
  padding: 5% 15px;
}
.custom-dy .layout_personal_center .layout_main_box,
.custom-dy .layout_personal_edit_username .layout_main_box,
.custom-dy .personal-box-edit {
  background: none;
}
.custom-dy .select_operate {
  background: #fff;
}
.custom-dy .select_operate .tab-bar {
  background: none;
}
.custom-dy .layout_personal_center_jifen,
.custom-dy .mall_link {
  padding: 3.4% 0;
  border-radius: 0;
  background: url(../assets/images/dy/postbg_top.png) no-repeat 0 0, url(../assets/images/dy/postbg_foot.png) no-repeat 0 100%;
  background-size: 100%;
}
.custom-dy .layout_personal_center_jifen > div,
.custom-dy .lbcenter_list_ul,
.custom-dy .layout_setbox_list {
  background: url(../assets/images/dy/postbg.png) repeat-y;
  background-size: 100%;
  width: 100%;
}
.custom-dy .layout_search .latest_posts_box {
  padding: 3.4% 0;
}
.custom-dy .layout_search .latest_posts_box > div.user-box-index-userInfo {
  padding-bottom: 0;
}
.custom-dy .lbcenter_list,
.custom-dy .layout_setbox_content {
  margin-top: 10px;
}
.custom-dy .mall_box_top {
  padding: 0 0 15px;
  border: none;
}
.custom-dy .mall_link {
  padding: 3% 5%;
}
.custom-dy .mall_link,
.custom-dy .mall_icon_link {
  margin: 15px 4% 0;
}
.custom-dy .mall_link .mall_link_l a {
  color: #848483;
}
.custom-dy .mall_link .mall_link_r a {
  color: #c6ae85;
}
.custom-dy .mall_jifen {
  background-image: url(../assets/images/dy/icon40.png);
}
@media screen and (max-width: 360px) {
  .custom-dy .mall_link {
    padding: 2% 5%;
  }
}
.custom-dy .mall_icon_link {
  justify-content: space-between;
}
.custom-dy .mall_icon {
  position: relative;
}
.custom-dy .mall_icon p {
  color: #fff;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
}
.custom-dy .mall_icon_pic img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.custom-dy .mall_icon.active p {
  font-weight: 700;
}
.custom-dy .mall_icon.active .mall_icon_pic img {
  -webkit-filter: none;
  filter: none;
}
.custom-dy .mall_list {
  border: none;
  margin-bottom: 15px;
  position: relative;
}
.custom-dy .mall_name {
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 2;
  height: 100%;
  padding-top: 3.5%;
  width: 110px;
}
.custom-dy .mall_txt {
  background: url(../assets/images/dy/postbg.png) repeat-y;
  background-size: 100%;
  padding: 0 20px 30px 140px;
  margin: 0;
  min-height: 100px;
}
.custom-dy .mall_dhbtn {
  right: 20px;
}
.custom-dy .stage_jifen {
  left: 140px;
}
.custom-dy .mall_pic {
  background: url(../assets/images/dy/mallbg.png) no-repeat;
  background-size: 100%;
  border-radius: 0;
  height: 61px;
  padding-top: 10px;
}
.custom-dy .stage_jifen p span,
.custom-dy .stage_jifen p em {
  color: #c6ae85;
}
.custom-dy .mall_dh {
  background: url(../assets/images/dy/gzbg.png) no-repeat;
  background-size: 100% 100%;
  color: #656564;
  padding: 0 5px;
  border-radius: 0;
  min-width: 53px;
  font-size: 13px;
  line-height: 24px;
}
.custom-dy .mall_dh_disabled {
  background-image: url(../assets/images/dy/gzbg02.png);
}
.custom-dy .edit-user-quit,
.custom-dy .edit-user-save {
  max-width: 390px;
  width: 40%;
  height: auto;
  color: #656564;
  padding: 3.5% 15px;
  background: url(../assets/images/dy/btnbg.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 0;
}
.custom-dy .edit-user-quit.disabled,
.custom-dy .edit-user-save.disabled {
  background: url(../assets/images/dy/btnbg02.png) no-repeat;
  background-size: 100% 100%;
}
.custom-dy .news_list_content ul li {
  position: relative;
}
.custom-dy .news_list_content ul li a p {
  padding-right: 30px;
}
.custom-dy .translate {
  width: 30px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: url(../assets/images/translate.png) no-repeat 100% 50%;
  background-size: 20px;
}
.custom-dy .check_translate {
  color: #e79d28;
  padding-bottom: 15px;
  display: inline-block;
}
.custom-dy .post-card-repost .post-card-repost-txt .post-card-repost-name {
  color: #5d98fa;
}
.custom-dy .list-index-commentItem .translate {
  height: 30px;
  position: static;
  float: right;
}
.custom-dy .list-index-content,
.custom-dy .list-index-commentItem-sublist .list-index-commentItem .list-index-content {
  margin-right: 30px;
  display: block;
}
.custom-dy .fresh {
  width: 30px;
  height: 30px;
  background: url(../assets/images/yxwd/fresh.png) no-repeat 0 0%;
}
.custom-dy .personal_informaion_title ul {
  overflow-x: auto;
  white-space: nowrap;
  display: block;
  font-size: 0;
}
.custom-dy .personal_informaion_title ul li {
  width: auto;
  min-width: 29%;
  display: inline-block;
  font-size: 14px;
}
.custom-dy .personal_informaion_title ul li a {
  width: 100%;
}
.custom-dy .personal_informaion_title ul li a .information-num {
  right: auto;
  left: 50%;
  margin-left: 11%;
}
.custom-dy .layout_search .latest_posts_box > div.user-box-index-userInfo + div {
  padding-top: 15px;
}
.custom-dy .mall_icon_link {
  overflow-x: auto;
  white-space: nowrap;
  display: block;
}
.custom-dy .mall_icon {
  width: auto;
  min-width: 20%;
  margin: 0 1% 0 0;
  display: inline-block;
}
.custom-dy .news_list_content ul li p {
  color: #656564;
}
.custom-dy .latest_posts_box > a {
  padding: 0 4%;
  background: url(../assets/images/dy/postbg.png) repeat-y;
  background-size: 100%;
  display: block;
}
.custom-dy .nomore {
  margin: 0;
  padding-top: 10px;
  background-image: url(../assets/images/dy/nomore.png);
}
.custom-dy .news_list_content ul li a > span img {
  opacity: 0;
}
.custom-dy .news_list_content ul li:nth-child(1) a > span {
  background: url(../assets/images/dy/icon01.png) no-repeat;
  background-size: 100%;
}
.custom-dy .news_list_content ul li:nth-child(2) a > span {
  background: url(../assets/images/dy/icon02.png) no-repeat;
  background-size: 100%;
}
.custom-dy .news_list_content ul li:nth-child(3) a > span {
  background: url(../assets/images/dy/icon03.png) no-repeat;
  background-size: 100%;
}
.custom-dy .layout_personal_center_link_list p img {
  opacity: 0;
}
.custom-dy .layout_personal_center_link_list:nth-child(1) p:nth-child(1) {
  background: url(../assets/images/dy/personal_02.png) no-repeat 50% 0;
  background-size: 70px;
}
.custom-dy .layout_personal_center_link_list:nth-child(2) p:nth-child(1) {
  background: url(../assets/images/dy/personal_04.png) no-repeat 50% 0;
  background-size: 70px;
}
.custom-dy .layout_personal_center_link_list:nth-child(3) p:nth-child(1) {
  background: url(../assets/images/dy/personal_05.png) no-repeat 50% 0;
  background-size: 70px;
}
.antd-pro-components-badge-index-badge {
  background: url(../assets/images/dy/icon07.png) no-repeat 50% 0;
  background-size: 18px;
}
.antd-pro-components-badge-index-badge img {
  opacity: 0;
}
