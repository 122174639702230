.antd-pro-components-comment-bar-index-commentBar .antd-pro-components-comment-bar-index-bar .antd-pro-components-comment-bar-index-input {
  height: 32px;
  line-height: 32px;
}
.antd-pro-components-comment-bar-index-input .fabu_content {
  margin-top: 0;
  height: auto;
}
.antd-pro-components-comment-bar-index-input .fabu_content .textContent {
  padding: 5px 0 0;
}
.antd-pro-components-comment-bar-index-input .fabu_content .textContent .ql-container .ql-editor {
  padding: 0;
}
