.custom-yxwd {
  /* 230411 */
}
.custom-yxwd .layout_top,
.custom-yxwd .layout_nav,
.custom-yxwd .tab-bar,
.custom-yxwd .layout_jubao .layout_top,
.custom-yxwd .layout_fabu .layout_top,
.custom-yxwd .layout_zhuanfa .layout_top,
.custom-yxwd .layout_select_huati .layout_top,
.custom-yxwd .layout_personal_center .layout_top.layout_top_scroll,
.custom-yxwd .layout_personal_edit_username .layout_top,
.custom-yxwd .layout_search .layout_top,
.custom-yxwd .layout_information .layout_top,
.custom-yxwd .layout_talk .layout_top.layout_top_scroll,
.custom-yxwd .layout_history .layout_top {
  background: #282f41;
}
.custom-yxwd .gametitle,
.custom-yxwd .layout_top_header .user-box-index-name,
.custom-yxwd .layout_return,
.custom-yxwd .personal_box_autograph,
.custom-yxwd .personal_box_autograph {
  color: #d7dadd;
}
.custom-yxwd .nav-list__item,
.custom-yxwd .personal-operation {
  color: #a4a4ad;
}
.custom-yxwd .nav-list__item.is-active,
.custom-yxwd .game_tequan_userInfor .personal-index-name span,
.custom-yxwd .layout_member_box_title,
.custom-yxwd .personal-index-name span,
.custom-yxwd .personal-index-id span,
.custom-yxwd .modify-head {
  color: #fff;
}
.custom-yxwd .nav-list__item em,
.custom-yxwd .selected_huati_wrap .search-synthetic-ph-btn .search-synthetic-ph-placeholder,
.custom-yxwd .antd-pro-components-editor-toolbar-index-sendBtn,
.custom-yxwd .talk_banner_box .operation-index-follow,
.custom-yxwd .edit-user-save,
.custom-yxwd .edit-user-sure,
.custom-yxwd .layout_member_main .layout_member_box_progressbar,
.custom-yxwd .layout_member_box_progressbar div,
.custom-yxwd .game_tequan_list_btn,
.custom-yxwd .personal-box-concern,
.custom-yxwd .personal-box-information,
.custom-yxwd .personal_informaion_title ul li a .information-num,
.custom-yxwd .unread,
.custom-yxwd .tab-bar-tab.tab04 .tab-bar-tab-icon .infor_more,
.custom-yxwd .tab-bar-tab.tab05 .tab-bar-tab-icon .infor_more,
.custom-yxwd .loginbox_btn,
.custom-yxwd .loginbox_btn_btn,
.custom-yxwd .layout_setbox_card,
.custom-yxwd .lcalendar_finish,
.custom-yxwd .lbcopy,
.custom-yxwd .lbcenter_list_btn,
.custom-yxwd .loginbox_dhbtn,
.custom-yxwd .mall_dh,
.custom-yxwd .layout_personal_center_link_list .information-num {
  background: #e79d28;
}
.custom-yxwd .operation-index-follow {
  border-color: #e79d28;
  color: #e79d28;
}
.custom-yxwd .operation-index-followed {
  border-color: #ccc;
  color: #ccc;
}
.custom-yxwd .search_result_content ul li {
  background: rgba(231, 157, 40, 0.5);
}
.custom-yxwd .search_result_content ul li p,
.custom-yxwd .stage_jifen p span {
  color: #7a541a;
}
.custom-yxwd .classify__item.is-active {
  background: rgba(231, 157, 40, 0.5);
  color: #7a541a;
}
.custom-yxwd .notice__item.is-active {
  border-color: #e79d28;
  color: #e79d28;
}
.custom-yxwd .layout_return span,
.custom-yxwd .layout_talk .layout_top.layout_top_scroll .layout_return span {
  border-color: #8a8a8a;
}
.custom-yxwd .list-index-name,
.custom-yxwd .post-card-repost .post-card-repost-txt .post-card-repost-name,
.custom-yxwd .huati_tab_item.is-active,
.custom-yxwd .selected_huati_list_box li a:after,
.custom-yxwd .jifen__item.is-active,
.custom-yxwd .layout_jifen_list_txt p span,
.custom-yxwd .layout_jifen_list_num,
.custom-yxwd .layout_member_box_num,
.custom-yxwd .layout_personal_center_name_username,
.custom-yxwd .personal_informaion_title ul li.icon_active a,
.custom-yxwd .personal_information_list_operate,
.custom-yxwd .select_delete,
.custom-yxwd .loginbox_tips span,
.custom-yxwd .loginbox_tips a,
.custom-yxwd .lcalendar_cancel,
.custom-yxwd .qiandao-btn,
.custom-yxwd .user-box-index-checking,
.custom-yxwd .selectlink {
  color: #e79d28;
}
.custom-yxwd .talk_edit_icon {
  background-color: #e79d28;
}
.custom-yxwd .layout_personal_center .layout_sub {
  background-image: url(../assets/images/mt/pic05.jpg);
}
.custom-yxwd .personal_jifen_box_num {
  background: rgba(40, 47, 65, 0.5);
}
.custom-yxwd .layout_personal_center .layout_close {
  background-image: url(../assets/images/close.png);
}
.custom-yxwd .layout_member_box_progressbar {
  border-color: #e79d28;
}
.custom-yxwd .personal-operation .operation_icon {
  background-image: url(../assets/images/mt/icon39.png);
}
.custom-yxwd .personal-operation .edit_icon {
  background-image: url(../assets/images/mt/edit.png);
}
.custom-yxwd .layout_personal_edit .personal_information {
  background-image: url(../assets/images/mt/pic06.jpg);
}
.custom-yxwd .select_header ul li img {
  border-color: rgba(231, 157, 40, 0.5);
}
.custom-yxwd .select_header ul li.select_active img {
  border-color: #e79d28;
}
.custom-yxwd .select_operate .tab-bar {
  background: transparent;
}
.custom-yxwd .send-action-item-dynamic .send-action-item-icon {
  background: url(../assets/images/mt/icon15.png) no-repeat 50% 50% / auto 20px;
}
.custom-yxwd .send-action-item-posts .send-action-item-icon {
  background: url(../assets/images/mt/icon16.png) no-repeat 50% 50% / auto 20px;
}
.custom-yxwd .send-action-item .send-action-item-icon {
  background-color: #eeba68;
}
.custom-yxwd .post-card-box-index-action-zan_click {
  background-image: url(../assets/images/mt/icon09.png);
}
.custom-yxwd .post-card-box-index-action-not_click {
  background-image: url(../assets/images/mt/icon19.png);
}
.custom-yxwd .layout_member_box_title img {
  -webkit-filter: brightness(0%);
  filter: brightness(0%);
}
.custom-yxwd .qiandao-btn-followed {
  background: rgba(231, 157, 40, 0.5);
  color: #7a541a;
}
.custom-yxwd .loginbox_lqbtn {
  background-image: url(../assets/images/mt/lqbtn.png);
}
.custom-yxwd .personal_informaion_title ul li a.icon_infor_reply {
  background: url(../assets/images/mt/icon27.png) no-repeat 50% 0;
  background-size: 50px;
}
.custom-yxwd .personal_informaion_title ul li a.icon_infor_zan {
  background: url(../assets/images/mt/icon28.png) no-repeat 50% 0;
  background-size: 50px;
}
.custom-yxwd .personal_informaion_title ul li a.icon_infor_fans {
  background: url(../assets/images/mt/icon31.png) no-repeat 50% 0;
  background-size: 50px;
}
.custom-yxwd .personal_informaion_title ul li a.icon_infor_system {
  background: url(../assets/images/mt/icon29.png) no-repeat 50% 0;
  background-size: 50px;
}
.custom-yxwd .personal_informaion_title ul li a.icon_infor_follow {
  background: url(../assets/images/mt/icon30.png) no-repeat 50% 0;
  background-size: 50px;
}
.custom-yxwd .personal_informaion_title ul li.icon_active a.icon_infor_reply {
  background-image: url(../assets/images/mt/icon27_2.png);
}
.custom-yxwd .personal_informaion_title ul li.icon_active a.icon_infor_zan {
  background-image: url(../assets/images/mt/icon28_2.png);
}
.custom-yxwd .personal_informaion_title ul li.icon_active a.icon_infor_fans {
  background-image: url(../assets/images/mt/icon31_2.png);
}
.custom-yxwd .personal_informaion_title ul li.icon_active a.icon_infor_system {
  background-image: url(../assets/images/mt/icon29_2.png);
}
.custom-yxwd .personal_informaion_title ul li.icon_active a.icon_infor_follow {
  background-image: url(../assets/images/mt/icon30_2.png);
}
.custom-yxwd .loginbox_btn_qx {
  background: #a6a6a6;
}
.custom-yxwd .mall_dh_disabled {
  background: #f2f2f2;
  color: #666666;
}
.custom-yxwd .layout_nav,
.custom-yxwd .layout_top_main,
.custom-yxwd .layout_personal_center .layout_top.layout_top_scroll {
  background: url(../assets/images/yxwd/top.png) no-repeat 0 0 / cover;
}
.custom-yxwd .tab-bar {
  background: url(../assets/images/yxwd/foot.png) no-repeat 0 0 / cover;
}
.custom-yxwd .layout_containter {
  background: url(../assets/images/yxwd/bg.png) no-repeat 0 0 / cover;
}
.custom-yxwd .layout_personal_center .layout_sub,
.custom-yxwd .layout_personal_center .layout_main_box,
.custom-yxwd .latest_posts_content,
.custom-yxwd .jubao_wrap,
.custom-yxwd .layout_personal_edit .personal_information,
.custom-yxwd .layout_personal_edit_username .layout_main_box,
.custom-yxwd #start_date,
.custom-yxwd .personal-box-edit,
.custom-yxwd .layout_personal_center .layout_top_main {
  background: none;
}
.custom-yxwd .news_list {
  background: #fff;
}
.custom-yxwd .components-commentList {
  border: none;
  background: #fff;
  border-radius: 10px;
  margin: 10px;
}
.custom-yxwd .antd-pro-components-comment-bar-index-commentBar .antd-pro-components-comment-bar-index-bar {
  height: 70px;
}
.custom-yxwd .latest_posts_box {
  background: #fff;
  border-radius: 10px;
  margin: 10px;
  overflow: hidden;
}
.custom-yxwd .layout_search .latest_posts_box {
  width: auto;
  margin: 10px;
}
.custom-yxwd .tab-bar-tab .tab-bar-tab-icon img {
  width: 42px;
}
.custom-yxwd .tab-bar {
  padding-bottom: 0;
}
.custom-yxwd .mall_box_top {
  border: none;
}
.custom-yxwd .mall_icon_link {
  justify-content: space-between;
}
.custom-yxwd .mall_list {
  background: #fff;
  border-radius: 10px;
  margin: 10px;
  overflow: hidden;
  border: none;
}
.custom-yxwd .no_data {
  color: #666;
}
.custom-yxwd .layout_personal_center {
  background: #fff6ec;
}
.custom-yxwd .layout_personal_center .layout_sub {
  background: url(../assets/images/yxwd/personal.jpg) no-repeat 0 0 #fff6ec;
  background-size: 100%;
}
.custom-yxwd .personal-info-box {
  color: #d7dadd;
}
.custom-yxwd .personal_jifen_box_qiandao {
  color: #d7dadd;
}
.custom-yxwd .antd-pro-components-comment-bar-index-commentBar .antd-pro-components-comment-bar-index-bar.antd-pro-components-comment-bar-index-focused {
  height: auto;
}
.custom-yxwd .fresh {
  width: 30px;
  height: 30px;
  background: url(../assets/images/yxwd/fresh.png) no-repeat 0 0;
  background-size: 100%;
}
.custom-yxwd #pictab {
  width: 100%;
}
.custom-yxwd #pictab img {
  border-radius: 0;
}
.custom-yxwd .layout_fabu .fabu-form,
.custom-yxwd .layout_fabu_action .fabu-form {
  padding: 0 15px;
  width: 94%;
  margin: 0 auto 10px;
  height: 60px;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #c1c0be;
}
.custom-yxwd .layout_fabu .fabu_wrap,
.custom-yxwd .layout_fabu_action .fabu_wrap {
  background: #fff;
  border-radius: 15px;
  width: 94%;
}
.custom-yxwd .layout_fabu .layout_main_box,
.custom-yxwd .layout_fabu_action .layout_main_box {
  padding-top: 10px;
}
.custom-yxwd .layout_fabu .fabu_title {
  width: 85%;
  margin: 25px auto 0;
  border: 1px solid #dfdfdf;
  height: 45px;
}
.custom-yxwd .layout_fabu .fabu_content,
.custom-yxwd .layout_fabu_action .fabu_content {
  width: 85%;
  margin: 25px auto;
  border: 1px solid #dfdfdf;
}
.custom-yxwd .layout_fabu_action .jubao_files {
  width: 85%;
  margin: 0 auto 10px;
}
.custom-yxwd .layout_fabu .fabu_position,
.custom-yxwd .layout_fabu_action .fabu_position {
  margin: 10px auto 0;
}
.custom-yxwd .tab-bar,
.custom-yxwd .border-one {
  border: none;
}
.custom-yxwd .layout_mall #pictab img {
  border-radius: 10px;
}
