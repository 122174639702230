.swiper-emoji-container {
  width: 100%;
  height: 100%;
  padding-bottom: 28px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  z-index: 99;
}
.swiper-emoji {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  box-sizing: content-box;
}
.swiper-emoji-item {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.swiper-emoji-item > li {
  width: 25%;
  height: 50%;
  display: flex;
}
.swiper-emoji-item > li > img {
  width: 80%;
  margin: auto;
}
.swiper-emoji-container .indicator-container {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: -18px;
}
.swiper-emoji-container .indicator-container .indicator-dot {
  width: 8px;
  height: 8px;
  opacity: 1;
  margin: 0 4px;
  background: #ccc;
  border-radius: 100%;
  display: inline-block;
}
.swiper-emoji-container .indicator-container .indicator-dot.active {
  background: #333;
}
