.indicator-container {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 10px;
}
.indicator-container .indicator-dot {
  width: 8px;
  height: 8px;
  opacity: 1;
  margin: 0 4px;
  background: #ccc;
  border-radius: 100%;
  display: inline-block;
}
.indicator-container .indicator-dot.active {
  background: white;
}
.contentFull {
  height: 100vh;
  color: #ffffff;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 48px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-y: auto;
}
.contentFull img {
  margin: auto;
}
.adm-dot-loading {
  width: 100%;
  text-align: center;
}
.custom-default {
  height: 100%;
  overflow: hidden;
  /* .layout_main_box > * { -webkit-animation: fadeIn 0.5s ease backwards;transform: translateZ(1px);-webkit-transform: translateZ(1px);-moz-transform: translateZ(1px);-ms-transform: translateZ(1px);-o-transform: translateZ(1px); } */
}
.custom-default .tab-bar-tab .tab-bar-tab-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-default .tab-bar-tab .tab-bar-tab-icon img {
  width: 27px;
}
.custom-default .tab-bar-tab .tab-bar-tab-icon img.publish {
  width: 42px;
}
.custom-default .tab-bar-tab .tab-bar-tab-icon .infor_more {
  background: #fc624f;
  border: 1px solid #fff;
  border-radius: 100%;
  position: absolute;
  top: 7px;
  left: 50%;
  margin-left: 5px;
  color: #fff;
  width: 16px;
  line-height: 1.2;
  font-size: 12px;
}
.custom-default .tab-bar-tab .tab-bar-tab-icon .infor_more_sample {
  width: 10px;
  height: 10px;
  background: #fc624f;
  border: 1px solid #fff;
  border-radius: 100%;
  position: absolute;
  top: 12px;
  left: 50%;
  margin-left: 9px;
}
.custom-default .posts_sort::after {
  margin-left: 5px;
}
.custom-default .user-box-index-info {
  min-height: 40px;
}
.custom-default .operation-index-follow {
  line-height: 23px;
}
.custom-default .components-index-name {
  max-width: none;
}
.custom-default .list-index-commentItem-sublist .list-index-commentItem .components-index-name span {
  color: #f57758;
  width: auto;
}
.custom-default .list-index-commentItem-sublist .list-index-commentItem .components-index-name span:first-child {
  width: 60px;
}
.custom-default .am-action-sheet-message p {
  display: flex;
  justify-content: center;
}
.custom-default .ql-editor.zh:empty:before {
  content: "展示你的文采...";
  color: #a5a5a5;
}
.custom-default .ql-editor.en:empty:before {
  content: "Write something here";
  color: #a5a5a5;
}
.custom-default .ql-editor.zh-TW:empty:before {
  content: "展示你的文采";
  color: #a5a5a5;
}
.custom-default .ql-editor.de:empty:before {
  content: "Schreib etwas hier";
  color: #a5a5a5;
}
.custom-default .ql-editor.fr:empty:before {
  content: "Écrivez quelque chose ici";
  color: #a5a5a5;
}
.custom-default .ql-editor.es:empty:before {
  content: "Escribe algo aquí";
  color: #a5a5a5;
}
.custom-default .ql-editor.pt:empty:before {
  content: "Escreva algo aqui";
  color: #a5a5a5;
}
.custom-default .ql-editor.ru:empty:before {
  content: "Напишите что-нибудь здесь";
  color: #a5a5a5;
}
.custom-default .ql-editor.nl:empty:before {
  content: "Schrijf hier iets";
  color: #a5a5a5;
}
.custom-default .ql-editor.pl:empty:before {
  content: "Napisz coś tutaj";
  color: #a5a5a5;
}
.custom-default .ql-editor.it:empty:before {
  content: "Scrivi qualcosa qui";
  color: #a5a5a5;
}
.custom-default .ql-editor.no:empty:before {
  content: "Skriv noe her";
  color: #a5a5a5;
}
.custom-default .ql-editor.sv:empty:before {
  content: "Skriv något här";
  color: #a5a5a5;
}
.custom-default .ql-editor.tr:empty:before {
  content: "Buraya bir şeyler yaz";
  color: #a5a5a5;
}
.custom-default .ql-editor.th:empty:before {
  content: "พิมพ์อะไรบางอย่างที่นี่";
  color: #a5a5a5;
}
.custom-default .ql-editor.ko:empty:before {
  content: "여기에 무엇을 작성하고 싶으세요";
  color: #a5a5a5;
}
.custom-default .ql-editor.ja:empty:before {
  content: "ここに書きたいことを入力してください";
  color: #a5a5a5;
}
.custom-default .ql-editor.id:empty:before {
  content: "Tulis sesuatu di sini";
  color: #a5a5a5;
}
.custom-default .ql-editor.vi:empty:before {
  content: "Viết gì đó ở đây";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.zh:empty:before {
  content: "分享你的新鲜事";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.en:empty:before {
  content: "What have you been up to lately?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.zh-TW:empty:before {
  content: "分享你的新鮮事";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.de:empty:before {
  content: "Qu'avez-vous fait dernièrement ?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.fr:empty:before {
  content: "Que has estado haciendo últimamente?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.es:empty:before {
  content: "O que você tem feito ultimamente?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.pt:empty:before {
  content: "O que você tem feito ultimamente?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.ru:empty:before {
  content: "Что нового у вас?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.nl:empty:before {
  content: "Wat heb je de laatste tijd gedaan?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.pl:empty:before {
  content: "Co robiłeś ostatnio?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.it:empty:before {
  content: "Cosa hai fatto di recente?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.no:empty:before {
  content: "Hva har du gjort i det siste?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.sv:empty:before {
  content: "Vad har du gjort på sistone?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.tr:empty:before {
  content: "Son zamanlarda ne yaptınız?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.th:empty:before {
  content: "คุณทำอะไรไปเมื่อเร็วๆ นี้?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.ko:empty:before {
  content: "최근에 무슨 일을 하셨나요?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.ja:empty:before {
  content: "最近はどんなことをしていましたか？";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.id:empty:before {
  content: "Apa yang sudah kamu lakukan belakangan ini?";
  color: #a5a5a5;
}
.custom-default .fabu_action .ql-editor.vi:empty:before {
  content: "Bạn đã làm gì gần đây?";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.zh:empty:before {
  content: "这个我想说...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.en:empty:before {
  content: "I'd like to say...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.zh-TW:empty:before {
  content: "這個我想說...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.de:empty:before {
  content: "Ich würde gerne sagen...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.fr:empty:before {
  content: "J'aimerais dire...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.es:empty:before {
  content: "Me gustaría decir...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.pt:empty:before {
  content: "Eu gostaria de dizer...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.ru:empty:before {
  content: "Я хотел бы сказать...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.nl:empty:before {
  content: "Ik zou willen zeggen...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.pl:empty:before {
  content: "Chciałbym powiedzieć...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.it:empty:before {
  content: "Mi piacerebbe dire...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.no:empty:before {
  content: "Jeg vil gjerne si...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.sv:empty:before {
  content: "Jag skulle vilja säga...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.tr:empty:before {
  content: "Söylemek istiyorum...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.th:empty:before {
  content: "ฉันอยากจะพูด...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.ko:empty:before {
  content: "말하고 싶습니다...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.ja:empty:before {
  content: "言いたいです...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.id:empty:before {
  content: "Saya ingin mengatakan...";
  color: #a5a5a5;
}
.custom-default .zhuanfa_action .ql-editor.vi:empty:before {
  content: "Tôi muốn nói...";
  color: #a5a5a5;
}
.custom-default .fabu_position_index p {
  line-height: 24px;
}
.custom-default .personal-operation {
  font-size: 14px;
  color: #414141;
}
.custom-default .tips_title {
  padding-top: 10px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.custom-default .tisps_txt {
  text-align: center;
}
.custom-default .tisps_txt.align_left {
  text-align: left;
}
.custom-default .personal_informaion_title ul li a .information-num {
  display: block;
}
.custom-default .select_operate {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 60px;
  z-index: 110;
}
.custom-default .no_data {
  color: #ccc;
  text-align: center;
  margin-top: 10px;
}
.custom-default .nomore {
  width: 100%;
}
.custom-default .personal_information_system_txt.has-unfold .system_txt {
  height: auto !important;
  -webkit-line-clamp: inherit !important;
}
.custom-default .personal_information_system_txt.has-unfold .system_txt_more {
  display: none !important;
}
.custom-default .personal_delete_btn {
  margin: 10px auto;
}
.custom-default .start_date {
  font-size: 16px;
  width: 100%;
  border: none;
}
.custom-default .search_result_content ul li {
  line-height: 22px;
}
.custom-default .select_huati_list .no_search_data {
  text-align: center;
}
.custom-default .layout_search .search_wrap,
.custom-default .layout_information .layout_top {
  background: #f8f8f8;
}
.custom-default .layout_search .search_wrap .search-synthetic-ph {
  background: #fff;
}
.custom-default .layout_jifen_list_ul {
  height: calc(100vh - 400px);
}
.custom-default .loginbox_yzm_time_active p {
  color: #f88d74;
  border-color: #f88d74;
}
.custom-default .mall_help {
  background: none;
}
.custom-default .mall_help > img {
  width: 100%;
  height: 100%;
  display: block;
}
.custom-default .layout_personal_edit .layout_main_box {
  position: relative;
}
.custom-default .version-txt {
  color: #a1a1a1;
  position: absolute;
  bottom: 2px;
  right: 0;
}
.custom-default .layout_jifen_record_list {
  padding: 20px 15px 30px;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
}
.custom-default .layout_jifen_record_list:last-child {
  border: none;
}
.custom-default .layout_jifen_record_list .layout_jifen_record_item {
  display: flex;
}
.custom-default .layout_jifen_record_list .layout_jifen_record_time {
  color: #a6a6a6;
  position: absolute;
  bottom: 5px;
}
.news_list_content ul li p {
  color: #fff;
}
